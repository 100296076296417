export {readFullComment};
function _readFullComment(el) {
  el.addEventListener('click', (event) => {
    const cl = el.closest('li');
    cl.classList.toggle('is-readmore-open');
  });

}
async function readFullComment(elements) {
  if (elements.length > 0) {
    elements.forEach(el => _readFullComment(el));
  }
}



